import $ from 'jquery';
const $window = $(window);
const $bodyHtml = $('body,html');
const $html = $('html');
const $body = $('body');
const breakPoint1 = 767;
const breakPoint2 = 1024;

/* ======================================
body fix
====================================== */
let scrollPosi;

function bodyFix() {
	scrollPosi = $(window).scrollTop();
	$body.css({
		position: 'fixed',
		width: '100%',
		'z-index': '1',
		top: -scrollPosi,
	});
}

function bodyFixReset() {
	$body.css({
		position: 'relative',
		width: 'auto',
		top: '0',
	});
	$bodyHtml.scrollTop(scrollPosi);
}
/* ======================================
js menuhambuger
====================================== */

$('.js-iconmenu').on('click', function () {
	$(this).toggleClass('is-active');
	$('.c-header__bottom').toggleClass('is-active');
	if ($('.js-iconmenu').hasClass('is-active')) {
		bodyFix();
	} else {
		bodyFixReset();
	}
});

/* ======================================
toggle submenu sp
====================================== */
$(function () {
	$('.js-subMenu').each(function () {
		$('.c-header__menu__txt', this).on('click', function () {
			if ($window.outerWidth() <= breakPoint2) {
				$(this).toggleClass('is-open');
				$(this).closest('.js-subMenu').find('.c-header__submenu').slideToggle();
			}
		});
	});
});

//===================================================
// format
//===================================================
$(window).on('resize load', function () {
	if ($(window).outerWidth() > breakPoint1) {
		$body.removeAttr('style');
		$('.js-subMenu').removeClass('is-open');
		$('.c-header__submenu').removeAttr('style');
	}
});
//===================================================
// gototop
//===================================================
$(window).on('scroll', function () {
	$('.js-pagetotop').addClass('is-scrolling');
	if ($(window).scrollTop() != 0) {
		if ($(window).scrollTop() > 200) {
			$('.js-pagetotop').addClass('is-show');
		} else {
			$('.js-pagetotop').removeClass('is-show');
		}
	} else {
		$('.js-pagetotop').addClass('is-scrolling');
	}
});

$('.js-pagetotop').on('click', function (e) {
	e.preventDefault();
	$('html, body').animate({ scrollTop: 0 }, 1000);
});
